import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import Loadable from "@loadable/component";

const RssFeed = Loadable(() => import('../components/feed_test'))


 export const query = graphql`
  query {
    hero: file(relativePath: { eq: "map_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFeedPanSurgFeed  (sort: { fields: [guid], order: DESC }) {
    edges {
      node {
        title
        pubDate
        link
        content
      }
    }
  } 
  }
` 

export default ({data}) => (
  <>

  <Hero title="News Feed"
        subtitle="View the latest developments from PanSurg."
        fluid={data.hero.childImageSharp.fluid}
  />

  <section className="section">
  {/* <div className="box has-background-white-bis" style={{padding:20, maxHeight:500, overflowX:"auto"}}>
  <p className="title has-text-darkBlue">Latest Updates</p>
  {data.allFeedPanSurgFeed.edges.map(({node}) => (  
  <div className="item">
<a className="subtitle has-text-red" href={node.link}>{node.title}</a><br />
   <span className="date is-size-6">{node.pubDate}</span><br />
    <span className="link"><a href={node.link}>
   {node.content}</a></span>
   <hr />
    </div>
  ))}
  
  </div> */} 
  <div className="box has-background-white-bis" style={{padding:20, maxHeight:500, overflowX:"auto"}}>
          <RssFeed />
          </div>
  </section> 
  </>
)

